import React, { useContext } from 'react';
import { GlobalDispatchContext } from '../../GlobalContextProvider';
import { StyledLogo, StyledWrapper, StyledTitle, LgogWrapper, StyledSubtitle, StyledTitleWrapper } from './StyledArticleCard';
import { Style, Circle, Fill, Stroke } from 'ol/style';

const ArticleCard = (props) => {
    const { opis, title, point, foto, link, feature, wartosc, grafika } = props;
    const dispatch = useContext(GlobalDispatchContext);
    return(
        <StyledWrapper onClick = {()=> {
                  dispatch({type: 'OPEN_ARTICLE', payload: true});
                  dispatch({type: 'FILL_ARTICLE_CONTENT', title: title, content: opis, foto: foto, link: link, point: point, wartosc: wartosc });
                  dispatch({type: 'SET_ACTIVE_FEATURE', payload: feature});
                  dispatch({type: 'SET_ACTIVE_FEATURE_ID', payload: feature.ol_uid})
                  feature.setStyle(()=> {
                    return new Style({
                        image: new Circle({
                            radius: 10,
                            fill: new Fill('#000'),
                            stroke: new Stroke({ color: 'red', width: 1.5 })
                        })
                        })
                  });
              }
            }
        >
            <LgogWrapper>
                <StyledLogo url = { grafika } />
            </LgogWrapper>
            <StyledTitleWrapper>
                <StyledTitle>{ title }</StyledTitle>
                <StyledSubtitle>Lidzbark Warmiński</StyledSubtitle>
            </StyledTitleWrapper>
        </StyledWrapper>
    )
};

export default ArticleCard;
