import {useEffect, useContext, useRef} from 'react';
import { GlobalStateContext } from '../GlobalContextProvider';
import { Style, Circle, Fill, Stroke } from 'ol/style';

const useStyle = () => {
    const state = useContext(GlobalStateContext);
    const activeId = state.activeFeatureId;
    const active = useRef(activeId);
    const isOpen = state.openArticleList;

    useEffect(()=> {
        if(isOpen) {
            active.current = activeId;
        } else {
            active.current = '0'
        }
        
    }, [activeId, isOpen])


    const featureStyle = ( feature ) => {
        const color = '#dc5098';
        if (active.current === '0') {
                  return new Style({
                    image: new Circle({
                      radius: 8,
                      fill: new Fill({ color }),
                      stroke: new Stroke({ color: '#fff', width: 1.5 })
                    })
                  })
                } else {
                  if (feature.ol_uid === active.current) {
                      return new Style({
                        image: new Circle({
                          radius: 8,
                          fill: new Fill({ color }),
                          stroke: new Stroke({ color: 'red', width: 1.5 })
                        })
                      })
                  } else {
                    return new Style({
                      image: new Circle({
                        radius: 8,
                        fill: new Fill({ color }),
                        stroke: new Stroke({ color: '#fff', width: 1.5 })
                      })
                    })
                  }
                }
    }


    return { featureStyle }
}

export default useStyle;
